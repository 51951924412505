import store from "@/store";
import Vue from "vue";
import {geol} from "../../main.js";
import {formatCurrency} from "../currencyFormater";
import moment from "moment";

// since the geol.local can change when ever werefresh the browser we need to set it every time we need to use it

if (store.getters['settings/getSettingValue']('geoloc_systemcountry')) {
    geol.locale = store.getters['settings/getSettingValue']('geoloc_systemcountry');
}

export function createCashBookPrintingData(items, userID) {
    let printData = [];


    for (let item of items) {
        printData.push({
            feed: 1
        });
        printData.push({
            font: "A"
        });
        printData.push({
            align: "center"
        });

        printData.push({
            fontSize: 2
        });

        printData.push({
            reverse: "on"
        });

        printData.push({
            text: geol.t('accounting.lang_cashBookEntry')
        });

        printData.push({
            reverse: "off"
        });

        printData.push({
            feed: 1
        });

        printData.push({
            fontSize: 1
        });

        printData.push({
            align: "left"
        });

        if(item.cashierID)
        printData.push({
            text: geol.t('generic.lang_cashierID') + ' : ' + item.cashierID
        });

        printData.push({
            text: geol.t('generic.lang_waiter') + ': ' + userID
        });

        printData.push({
            text: geol.t('accounting.lang_invoiceType') + ' : ' + (item.type === 1 ? geol.t('accounting.lang_income') : geol.t('accounting.lang_expense'))
        });

        if(item.account)
        printData.push({
            text: geol.t('accounting.langAccountingAccount') + ' : ' + item.account
        });

        if(item.total)
        printData.push({
            text: geol.t("generic.lang_total") + ' : ' + formatCurrency(item.total)
        });

        if(item.tax)
        printData.push({
            text: geol.t('accounting.lang_taxes') + ' : ' + item.tax + ' %'
        });

        if(item.no)
        printData.push({
            text: geol.t('accounting.lang_invoiceNo') + ' : ' + item.no
        });

        if(item.date)
        printData.push({
            text: geol.t('accounting.lang_receiptDate') + ' : ' + moment(item.date,'YYYY-MM-DD').format('MM.DD.YYYY')
        });

        if(item.freeText)
        printData.push({
            text: geol.t('generic.lang_Freitext') + ' : ' + item.freeText
        });

        printData.push({
            align: "center"
        });
        printData.push({
            text: "_____________________________________"
        });
        printData.push({
            text: geol.t('generic.lang_signature')
        });
        printData.push({
            align: "left"
        });
        printData.push({
            feed: 4
        });

        printData.push({
            cut: true,
        });
    }

    return printData;
}