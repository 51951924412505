<template>
  <div>
    <v-container v-if="loading && items.length === 0">
      <v-row>
        <v-col cols="12" class="text-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>

    <div v-else>
      <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="mb-3 card">
                    <span class="dataTableAddItem" style="right:0px;position:absolute;top:-120px;">
                      <button @click="showCreateEntryDialog = true" class="btn-shadow d-inline-flex align-items-center btn btn-success pl-1 ma-2"
                              type="button">
                            <v-icon class="mr-1 white--text pa-0" icon="plus">add</v-icon>
                            {{$t('generic.lang_create')}}
                        </button>
                    </span>

        <v-dialog v-model="dialogEdit" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ $t('accounting.lang_editCashBookEntry') }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form v-model="valid" lazy-validation style="width: 100%;">
                  <v-row>

                    <v-col cols="12" sm="6">
                      <v-select outlined
                                :items="cashierIDs"
                                v-model="cashierIDEditVal"
                                :label="$t('generic.lang_cashierID')"
                                required
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select outlined
                                item-text="text" item-value="value"
                                :items="[{text: $t('accounting.lang_income'),value: 1},{text: $t('accounting.lang_expense'),value: 0}]"
                                v-model="editEntryType"
                                :label="$t('accounting.lang_invoiceType')"
                                required
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-select outlined item-text="text" item-value="value"
                                v-model="editEntryAccount"
                                :label="$t('accounting.langAccountingAccount')+':'"
                                v-if="accounts && accounts != null" :items="accounts"></v-select>

                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-select :items="itemTaxSelect"
                                :label="$t('erp.lang_tax_value')"
                                item-text="name"
                                item-value="tax"
                                outlined
                                v-model="editEntryTax"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-currency-field
                          :label="$t('accounting.lang_grossAmountIn') + $store.getters['settings/getSettingValue']('symbol')"
                          outlined
                          required
                          v-model="editEntryTotal"
                          @focus="showTouchKeyboard"
                          :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                          :min="0"
                          :auto-decimal-mode="true"
                          :max="editEntryType===0?endCreditTotal:null"
                          :local="$store.getters['settings/getSettingValue']('geoloc_systemcountry')"
                          :allow-negative="false"
                          :rules="[rules.checkTotalWithEndCredit,]"
                      ></v-currency-field>
                    </v-col>
                  </v-row>


                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field outlined
                                    @focus="showTouchKeyboard"
                                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    v-model="editEntryBelNo"
                                    :label="$t('accounting.lang_invoiceNo')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <date-picker :label="$t('accounting.lang_receiptDate')" v-model="editEntryBelDate"/>
                    </v-col>

                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-textarea outlined
                                  @focus="showTouchKeyboard"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :label="$t('generic.lang_Freitext')"
                                  v-model="editEntryFreetext"
                      ></v-textarea>
                    </v-col>
                  </v-row>
              </v-form>

              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogEdit = false">{{ $t('generic.lang_close') }}</v-btn>
              <v-btn color="blue darken-1" :disabled="!valid" text
                     @click="editCashbookEntry()">
                {{$t('generic.lang_edit')}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-container fluid>
          <v-layout>
            <v-flex md4 mr-2 ml-2>
              <v-select outlined item-text="text" item-value="value" v-model="cashierIDsVal"
                        @change="loadData(true)"
                        :label="$t('generic.lang_cashierID')+':'"
                        v-if="cashierIDs && cashierIDs != null" :items="cashierIDs"></v-select>
            </v-flex>
            <v-flex pt-5 md4 mr-2 ml-2>
              {{ $t('accounting.lang_openingBalancePeriod') }}: <b>{{ startCredit | currency }}</b>
            </v-flex>
            <v-flex md4 mr-2 ml-2>
              <v-flex>
                <v-btn color="warning" block @click="cashBookCloseMonth">
                  {{ $t('accounting.lang_cashBookCompleteMonth') }}
                </v-btn>
              </v-flex>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex md4 mr-2 ml-2>
              <v-select outlined item-text="text" item-value="value" v-model="periodVal"
                        :label="$t('accounting.lang_period')+':'"
                        v-if="period && period != null" :items="period"></v-select>
            </v-flex>
            <v-flex md4 pt-5 mr-2 ml-2>
              {{ $t('accounting.lang_closingBalancePeriod') }}: <b>{{ endCredit | currency }}</b>
            </v-flex>
            <v-flex md4 mr-2 ml-2>
              <v-flex>
                <v-btn color="success" block @click="loadData(true)">{{
                    $t('generic.lang_filter')
                  }}
                </v-btn>
              </v-flex>
            </v-flex>
          </v-layout>
        </v-container>

      </div>

      <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="mb-3 card">
        <v-card-title style="font-size:100%;">{{ $t('generic.lang_cashBookEntriesMonth') }} {{ cashbookActiveMonth }}<v-spacer/>
          <v-btn @click="exportCashbookPDF" outlined color="error" :loading="loadingPDFExport||loading" :disabled="loadingPDFExport||loading">{{ $t('generic.lang_ExportAsPDF') }} <v-icon class="mx-1">picture_as_pdf</v-icon></v-btn>
        </v-card-title>

        <v-container v-if="loading">
          <v-row>
            <v-col cols="12" class="text-center">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-col>
          </v-row>
        </v-container>

        <div v-else>
          <v-container fluid>
            <v-layout>
              <v-flex md12>
                <!-- Table -->
                <div style="margin-top: 20px;">
                  <b-table ref="cashOverviewTable" striped hover responsive
                           :items="items"
                           :fields="fields" :table-variant="this.$vuetify.theme.dark? 'dark' : ''">
                    <template v-slot:cell(EINNAHME)="row">
                      {{ row.item.EINNAHME | currency}}
                    </template>
                    <template v-slot:cell(AUSGABE)="row">
                      {{ row.item.AUSGABE | currency}}
                    </template>
                    <template v-slot:cell(STEUER)="row">
                      {{ row.item.STEUER }} %
                    </template>
                    <template v-slot:cell(AKTION)="row">
                      <!-- We use @click.stop here to prevent a 'row-clicked' event from also happening -->
                      <v-btn v-if="row.item.CLOSED < 1" size="sm" icon text color="primary"
                             @click="editEntryDialog(row.item.ENTRYUUID)">
                        <v-icon>edit</v-icon>
                      </v-btn>
                      <v-btn v-if="row.item.CLOSED < 1" icon text color="error"
                             @click="deleteEntry(row.item.ENTRYUUID)">
                        <v-icon medium>delete</v-icon>
                      </v-btn>

                    </template>
                  </b-table>
                </div>

              </v-flex>
            </v-layout>
          </v-container>
        </div>
      </div>
    </div>
    <template v-if="$vuetify.breakpoint.xs">
      <CashbookCreateEntryDialog
          v-if="!loading"
          :taxes="itemTaxSelect"
          :accounts="accounts"
          :cashier-i-ds="cashierIDs"
          :show-dialog="showCreateEntryDialog"
          @closeDialog="showCreateEntryDialog = false"
          @entryCreated="closeCreateEntryDialog"
      ></CashbookCreateEntryDialog>
    </template>
    <template v-else>
      <BulkCashbookCreateEntryDialog v-if="showCreateEntryDialog&&!loading" :taxes="itemTaxSelect" :accounts="accounts" :cashier-i-ds="cashierIDs" :cashier-i-d="cashierIDsVal" :end-credit="endCreditTotal"
                                     :show-dialog="showCreateEntryDialog" @closeDialog="showCreateEntryDialog = false"
                                     @entryCreated="closeCreateEntryDialog"></BulkCashbookCreateEntryDialog>
    </template>


    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
          id="onScreenKeyboard"
          class="internalWidthExpanded"
          :options="touchKeyboard.options"
          :defaultKeySet="touchKeyboard.keySet"
          v-if="touchKeyboard.visible"
          :layout="touchKeyboard.layout"
          :cancel="hideTouchKeyboard"
          :accept="hideTouchKeyboard"
          :input="touchKeyboard.input"/>
    </div>
  </div>
</template>

<style>
.dataTables_filter {
  display: none;
}
</style>

<script>
import {ENDPOINTS} from '../../config'
import DateTimePicker from "../common/datetimepicker";
import mixin from "../../mixins/KeyboardMixIns";
import {Events} from "../../plugins/events";
import {mapState} from "vuex"
import DatePicker from "../common/datepicker";
import BulkCashbookCreateEntryDialog from "./BulkCashbookCreateEntryDialog";
import CashbookCreateEntryDialog from "./CashbookCreateEntryDialog";
import FileSaver from 'file-saver';

export default {
  components: {
    CashbookCreateEntryDialog,
    BulkCashbookCreateEntryDialog,
    DatePicker,
    DateTimePicker
  },

  mixins: [mixin],

  data() {
    return {
      showCreateEntryDialog: false,
      dialogEdit: false,
      loadingPDFExport: false,
      fields: [
        {
          key: 'BUCHUNGSDATUM',
          label: this.$t('accounting.lang_bookingDate'),
          sortable: false,
          'class': 'text-center'
        },
        {
          key: 'BELEGDATUM',
          label: this.$t('accounting.lang_receiptDate'),
          sortable: false,
          'class': 'text-center'
        },
        {
          key: 'KONTO',
          label: this.$t('accounting.langAccountingAccount'),
          sortable: false,
          'class': 'text-center'
        },
        {key: 'EINNAHME', label: this.$t('accounting.lang_income'), sortable: false, 'class': 'text-center'},
        {key: 'AUSGABE', label: this.$t('accounting.lang_expense'), sortable: false, 'class': 'text-center'},
        {
          key: 'currentCash',
          label: this.$t('accounting.lang_currentCash'),
          sortable: false,
          'class': 'text-center'
        },
        {key: 'BELEGNR', label: this.$t('accounting.lang_invoiceNo'), sortable: false, 'class': 'text-center'},
        {key: 'STEUER', label: this.$t('accounting.lang_taxes'), sortable: false, 'class': 'text-center'},
        {key: 'AKTION', label: this.$t('generic.lang_action'), sortable: false, 'class': 'text-center'},

      ],
      cashierIDs: null,
      accounts: null,
      period: null,
      cashierIDsVal: 1,
      cashBookEntryID: null,
      cashierIDEditVal: 1,
      editEntryType: "",
      editEntryTax: 0.00,
      editEntryAccount: null,
      editEntryBelNo: "",
      editEntryBelDate: "",
      editEntryFreetext: "",
      editEntryTotal: "",
      itemTax: null,
      itemTaxSelect: [],
      periodVal: null,
      startCredit: "",
      endCredit: "",
      endCreditTotal:0,
      cashbookActiveMonth: "",
      dailyReportID: null,
      paymentOption: null,
      items: [],
      loading: false,
      valid: false,
      tab: 0,
      rules:{
        checkTotalWithEndCredit:(v)=>{

          let val =(v+'').replace('.','')
          val = Number(val.replace(',','.'))

          if(isNaN(val)){
            return this.$t('generic.lang_pleaseEnterValidNumber')
          }

          if(this.createEntryType===1){
            return  !!val||this.$t('generic.lang_requiredField');
          }
          if(Number(val) > Number(this.endCredit)){
            return this.$t('generic.lang_shouldBeLessThanTheEndingBalance')
          }
          return !!val || this.$t('generic.lang_requiredField');
        },
      },
    }
  },

  mounted() {
    this.loadData();
    this.cashierIDsVal = this.api.auth.cashierID;
  },

  computed: {
    ...mapState([
      'api',
    ]),
  },

  methods: {
    exportCashbookPDF() {
      this.loadingPDFExport = true;
      this.axios.post(ENDPOINTS.ACCOUNTING.CASHBOOK.PRINTA4, {
            cashierID:this.cashierIDsVal,
            cashBookPeriod:this.periodVal,
          },
          {
            responseType: 'arraybuffer',
          }).then((res) => {

        FileSaver.saveAs(new Blob([res.data], {
          type: "application/pdf"
        }), "Cashbook_" + this.periodVal + ".pdf");
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred')+' '+err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingPDFExport = false;
      })
    },
    checkNegativity(val) {
      let newVal = val.toString();
      newVal = newVal.replace(",", "");
      newVal = newVal.replace(".", "");
      if (Number(newVal) < 0 || isNaN(newVal)) this.editEntryTotal = 0;

    },
    resetData: function () {
      this.cashierIDs = null
      this.period = null
      this.dailyReportID = null
      this.paymentOption = null
    },
    loadData(filterEntries = false) {
      this.loading = true;

      //CHECK IF SHOULD FILTER ENTRIES
      let getPayload = {};

      if (filterEntries) {
        getPayload = {
          action: "filter",
          cashBookCashierID: this.cashierIDsVal,
          cashBookPeriod: this.periodVal,
        };
      }

      this.axios.post(ENDPOINTS.ACCOUNTING.CASHBOOK.GET, getPayload).then((result) => {
        let data = result.data;
        let items = [];
        let cashierIDsOptions = [];
        let periodOptions = [];
        let accountOptions = [];


        data.tax.forEach((row) => {
          this.itemTaxSelect.push({name: row[1], tax: parseFloat(row[0])});
        });

        if (data.cashierIDs && data.cashierIDs != null && data.cashierIDs.length > 0) {
          for (let i = 0; i < data.cashierIDs.length; i++) {
            let item = data.cashierIDs[i];
            cashierIDsOptions.push({text: item[1], value: item[0]});
          }
        }
        this.cashierIDs = cashierIDsOptions;
        if (data.cashBookPeriod && data.cashBookPeriod != null && data.cashBookPeriod.length > 0) {
          for (let i = 0; i < data.cashBookPeriod.length; i++) {
            let item = data.cashBookPeriod[i];
            periodOptions.push({text: item[1], value: item[0]});
          }
        }
        this.period = periodOptions;

        if (data.accounts && data.accounts != null && data.accounts.length > 0) {
          for (let i = 0; i < data.accounts.length; i++) {
            let item = data.accounts[i];
            accountOptions.push({text: item[1], value: item[0]});
          }
        }
        this.accounts = accountOptions;

        this.startCredit = data.formfillData.additionalText.startBarbestand;
        this.endCredit = data.formfillData.additionalText.lfdBarbestand;
        this.endCreditTotal = parseFloat(data.formfillData.additionalText.lfdBarbestand);
        this.periodVal = data.formfillData.additionalText.cashBookActivePeriod;
        this.cashbookActiveMonth = data.formfillData.additionalText.cashBookMonth;

        let itemOptions = [];
        if (data.cashBookEntriesArr && data.cashBookEntriesArr != null && data.cashBookEntriesArr.length > 0) {
          for (let i = 0; i < data.cashBookEntriesArr.length; i++) {
            let item = data.cashBookEntriesArr[i];
            itemOptions.push({
              ENTRYUUID: item[0],
              ENTRYID: item[1],
              BUCHUNGSDATUM: item[2],
              BELEGDATUM: item[3],
              KONTO: item[4],
              EINNAHME: item[5],
              AUSGABE: item[6],
              currentCash: item[7],
              BELEGNR: item[8],
              STEUER: item[9],
              CLOSED: item[10]
            });
          }
        }

        this.items = itemOptions;
        this.loading = false;
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });

        this.loading = false;
      })
    },
    cashBookCloseMonth: function () {
      let self = this;
      this.$swal({
        title: this.$t('accounting.lang_cashBookCloseMonthTitle'),
        text: this.$t('accounting.lang_cashBookCloseMonthText'),
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_commit'),
        showLoaderOnConfirm: true,
        preConfirm: () => {

          this.axios.post(ENDPOINTS.ACCOUNTING.CASHBOOK.LOCK, {
            cashBookCashierID: self.cashierIDsVal,
            cashBookCashierPeriod: self.periodVal,
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('accounting.lang_monthHasBeenFixed'),
                color: "success"
              });
              self.loadData();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    editEntryDialog: function (id) {

      let self = this;

      self.axios.post(ENDPOINTS.ACCOUNTING.CASHBOOK.GETENTRY, {
        cashBookEntryID: id
      }).then(function (result) {
        let data = result.data;
        let formfields = data.formfillData.textFields;

        self.cashBookEntryID = id;
        self.cashierIDEditVal = formfields.cashierID;
        self.editEntryType = formfields.belegart;
        self.editEntryTax = formfields.mwststeuer;
        self.editEntryAccount = formfields.accountID;
        self.editEntryBelNo = formfields.rechnungsnr;
        self.editEntryBelDate = formfields.optReceiptDateTimeStamp;
        self.editEntryFreetext = formfields.freitext;
        self.editEntryTotal = formfields.betragBrutto;

        self.dialogEdit = true;
      })


    },
    editCashbookEntry: function () {
      let self = this;
      this.axios.post(ENDPOINTS.ACCOUNTING.CASHBOOK.UPDATE, {
        cashBookIDEntry: this.cashBookEntryID,
        cashBookCashierIDEntry: this.cashierIDEditVal,
        buchungsBelegArt: this.editEntryType,
        buchungsSatzSteuer: this.editEntryTax,
        cashBookKontoNo: this.editEntryAccount,
        cashBookBelegRr: this.editEntryBelNo,
        Belegdatum: this.editEntryBelDate,
        cashBookNotes: this.editEntryFreetext,
        cashBookTotal: this.editEntryTotal,
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('accounting.lang_cashbookEntryEdited'),
            color: "success"
          });
          self.loadData();
          this.dialogEdit = false;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    deleteEntry: function (id) {
      let self = this;
      this.$swal({
        title: this.$t('generic.lang_deletedEntryTitle'),
        text: this.$t('generic.lang_deletedCashBookEntryText'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {

          this.axios.post(ENDPOINTS.ACCOUNTING.CASHBOOK.DELETE, {
            cashBookEntryID: id
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_entryHasBeenDeleted'),
                color: "success"
              });
              self.loadData();

            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    closeCreateEntryDialog() {
      this.loadData(true);
      this.showCreateEntryDialog = false;

    }
  }
}
</script>
